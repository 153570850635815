export const Utils = {
  init: function() {
    this.SetDataBackgrounds();
    this.SetHeroToActiveOnLoad();
  },
  SetDataBackgrounds: () => {
    $('[data-background]').each(function() {
      $(this).css('background-image', 'url('+ $(this).attr('data-background') + ')');
    });
  },
  SetHeroToActiveOnLoad: () => {
    setTimeout(() => {
      $('.hero-item').addClass('active');
    }, 400);
  }
}
