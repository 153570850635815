export const Slider = {
  init: function() {
    this.InitTeamSlider();
  },
  InitTeamSlider: () => {
    $('#innerbox-slider').owlCarousel({
      margin: 30,
      responsiveClass: true,
      nav: false,
      dots: true,
      loop: true,
      autoplay: false,
      smartSpeed: 1000,
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 1,
        },
        600: {
          items: 2,
        },
        700: {
          items: 2,
        },
        1000: {
          items: 3,
        },
        1300: {
          items: 3,
        },
        1600: {
          items: 3,
        },
      },
    });
  }
}