export const Header = {
  init: function() {
    this.InitStickyHeader(),
    this.ToggleOverlayOnScroll();
    this.SetNavLinkActiveOnScroll();
  },
  InitStickyHeader: () => {
    $('.one-page-scroll ul li a, .smooth-scroll').on("click", function(){
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name="DCSext.Level"' + this.hash.slice(1) +']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: target.offset().top -80
          }, 1000);
          return false;
        }
      }
    });
  },
  SetNavLinkActiveOnScroll() {
    const sections = document.querySelectorAll('section');
    const navLinks = document.querySelectorAll('nav #main-nav .nav-item a.nav-link');

    window.onscroll = () => {
      let current = '';
    
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.pageYOffset >= sectionTop - 200) {
          current = section.getAttribute('id'); 
        }
      });
    
      navLinks.forEach((link) => {
        link.classList.remove('active');
        const sectionId = link.href.split('#')[1] || '';
        if (sectionId === current) {
          link.classList.add('active');
        }
      });
    };
  },
  ToggleOverlayOnScroll: () => {
    $(window).on('scroll', function() {
      if ($(window).scrollTop() > 100) {
        $('.main-header').addClass('sticky-header-overlay')
      } else {
        $('.main-header').removeClass('sticky-header-overlay')
      }
    });
  }
};

/* =========================
  # Add anchor links
 =========================== */

$(document).ready(function() {
  if(window.location.hash) {
    // Show sticky header
    $('#header-id').addClass('sticky-header-overlay');
  }
});

