export const ScrollUp = {
  init: function () {
    this.OnClick();
  },
  OnClick: () => {
    $('.scrollup').on("click", function () {
      $("html, body").animate({
        scrollTop: 0
      }, 800);
      return false;
    });
  }
}