export const MobileMenu = {
  init: function() {
    this.onOpenMenu();
    this.onMenuItemClick();
  },
  onOpenMenu: () => {
    $('.open_mobile_menu').on("click", function() {
      $('.mobile_menu_wrap').toggleClass("mobile_menu_on");
      $('body').toggleClass('mobile_menu_overlay_on');
    });
  },
  onMenuItemClick: () => {
    $('.mobile-main-navigation ul li a').on('click', function () {
      $('body').removeClass('mobile_menu_overlay_on');
      $('.mobile_menu_wrap').removeClass("mobile_menu_on");
    });
  }
}