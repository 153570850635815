export const Card = {
  init: function() {
    this.ToggleSocial();
  },
  ToggleSocial: () => {
    $('.innerbox-img-social').on("click", function() {
      if ( $(this).hasClass('social-show') ) {
        $(this).removeClass('social-show');
      } else {
        $('.innerbox-img-social.social-show').removeClass('social-show');
        $(this).addClass('social-show');    
      }
    });
  }
};