'use strict';

// Packages
import 'jquery';
import 'popper.js';
import 'bootstrap';
import 'owl.carousel';

// Components
import { Utils } from './utils/utils';
import { Header } from './components/header';
import { Slider } from './components/slider';
import { MobileMenu } from './components/mobile-menu';
import { ScrollUp } from './components/scroll-up';
import { Card } from './components/card';
import { ChangeFontSize } from './components/changefontsize';
import { PrivacyBanner } from './components/privacy-banner';

const App = {
	init: function() {
		Utils.init();
		Header.init();
		Slider.init();
		MobileMenu.init();
		ScrollUp.init();
		Card.init();
	}
}

$(function() {
	App.init();
});
