/* =========================
  # Switch font size 
 =========================== */

  $('.change-font').on('click', 'a', function (e) {
    e.preventDefault();
    $('.change-font a.size').removeClass('active');
    $(this).addClass('active');
    if ($(this).hasClass('font-size-1')) {
      $('body').removeClass('text-size2 text-size3');
    } else if ($(this).hasClass('font-size-2')) {
      $('body').removeClass('text-size3').addClass('text-size2');
    } else if ($(this).hasClass('font-size-3')) {
      $('body').removeClass('text-size2').addClass('text-size3');
    }
  });

  $(document).on('click', '.menu-hamburger', function (e) {
    if ($('.overlay').hasClass('open')) {
      $('.overlay').removeClass('open');
    } else {
      $('.overlay').addClass('open');
    }
    e.preventDefault();
  });

  $(document).mouseup(function (e) {
    var container = $('.box-wrap');

    if (!container.is(e.target)
      && container.has(e.target).length === 0) {
      $('.overlay').removeClass('open');
    }
  });

  $('.prefooter .footer-col').on('click', 'h3', function (e) {
    $(this).toggleClass('active');
    $(this).parent().find('ul, .address').toggleClass('open');
    e.preventDefault();
  });